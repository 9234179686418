
.q-stepper {
	border: none;
	padding: 0;
}
.extension-icon {
	border-radius: 25px;
	margin-top: 15px;
	height: 50px;
	width: 165px;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("../../assets/_img/CharacterSyncExtensionIcon.webp");
}
